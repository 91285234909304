/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --color-pagination-link-text: rgba(51, 51, 51, .5);
    --color-pagination-link-active-text: var(--color-white);
    --pagination-line-height: var(--imported_pagination_line_height, 20px);
    --pagination-font-size: var(--imported_pagination_font_size, 14px);
    --pagination-font-style: var(--imported_pagination_font_style, normal);
}

.PaginationLink {
    border: none;
    border-radius: 0;
    color: var(--color-pagination-link-text);
    font-size: var(--pagination-font-size);
    font-style: var(--pagination-font-style);
    display: block;
    line-height: var(--pagination-line-height);
    margin-inline-end: 15px;
    padding: 7px 13px;
    text-align: center;
    background-color: var(--color-white);

    &:hover {
        --color-pagination-link-text: var(--color-pagination-link-active-text);

        text-decoration: none;
        background-color: var(--primary-base-color);
        color: var(--color-pagination-link-active-text);
    }

    &_isCurrent {
        --color-pagination-link-text: var(--color-pagination-link-active-text);
        
        color: var(--color-pagination-link-active-text);
        background-color: var(--primary-base-color);
        border-color: var(--color-pagination-link-active-text);
    }

    &_isArrow {
        padding: 7px 11px;
        background-color: transparent;

        svg {
            fill: var(--color-pagination-link-text);
        }

        &:hover {
            background-color: transparent;

            svg {
                fill: #333333;
            }
        }
    }
}
