.StoreUsp {
    display: flex;
    margin: 20px 0;

    &-Item {
        flex-basis: 10px;
        flex-grow: 1;
        font-size: 18px;
        text-align: center;
        font-weight: bold;
        color: var(--primary-base-color);

        &:not(:last-child) {
            border-right: 1px solid var(--primary-divider-color);
        }
    }
}
