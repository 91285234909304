// stylelint-disable declaration-no-important

.CmsBlock-Wrapper {
    .pagebuilder-mobile-hidden {
        @include mobile {
            display: none !important;
        }
    }

    .pagebuilder-mobile-only {
        @include desktop {
            display: none !important;
        }
    }
}

// RangeSlider

.RangeSlider {
    .input-range {
        &__track {
            height: 8px;
            background: var(--color-white);
            border-radius: 0;

            &--active {
                background: var(--primary-base-color);
            }

            &--background {
                inset-block-start: calc(50% - 8px);
                margin-block-start: 0;
            }
        }

        &__slider {
            background: white;
            border: 0;
            margin-block-start: -12px;
            height: 16px;
            width: 16px;
            margin-inline-start: -8px;
            box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.1), 0 6px 16px rgba(0, 0, 0, 0.1), 0 9px 28px 8px rgba(0, 0, 0, 0.1);
        }
    }
}

.aa-Input:focus {
    border: 0;
}

#AlgoliaAutocompleteMain {
    #algoliaProductsFooter {
        display: none;
    }
}
