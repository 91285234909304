/* stylelint-disable declaration-no-important */
/* Home Page */

:root {
    --color-bg-home-transparent: #F5F2F2;
    --color-bg-home-white: #FFF;
    --color-bg-home-dark: #333;
}

.row {
    &__transparent {
        background-color: var(--color-bg-home-transparent);
    }

    &__white {
        background-color: var(--color-bg-home-white);
    }

    &__dark {
        background-color: var(--color-bg-home-dark);
    }

    .pagebuilder-button-link {
        font-weight: bold !important;
    }

    .pagebuilder-button-primary {
        margin: 20px 0 0 0;
        border: 0;
        border-radius: 0;
        padding-inline: 40px;
        font-size: 13px;
        height: 42px;
    }

    h2 {
        font-weight: normal;
    }

    &-productTest {
        .__orange {
            p {
                color: var(--primary-base-color);
                font-weight: 600;
            }
        }

        p,
        h2 {
            color: var(--color-white);
            margin-block-end: 8px;
        }
    }

    &-brands {
        h2 {
            margin-block: 50px;
        }

        .CategoryPromo {
            margin-block-end: 40px;
            grid-template-columns: repeat(6, 1fr);

            @include mobile {
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                margin-inline: 16px;
                padding: 0;
                gap: 16px;
                grid-template-rows: auto;
            }

            &-Item {
                padding: 0;
                display: flex;
                flex-direction: column;
                background-color: var(--color-white);

                .block-category-link {
                    position: initial;
                    height: 48px;

                    a {
                        cursor: pointer;
                    }

                    span {
                        background-color: var(--color-white);
                        color: var(--color-black);
                        font-size: 14px;
                        margin: 0;
                        padding-block-end: 20px;
                    }
                }
            }

            .PromoItem {
                &-Image {
                    position: initial;
                    height: 80px;
                    width: auto;
                    object-fit: contain;
                    padding: 40px 20px 20px;
                    box-sizing: content-box;
                    background: var(--color-white);
                }
            }
        }
    }

    &-about {
        @include mobile {
            padding-block: 32px !important;
        }

        h2 {
            margin-block-start: 0;
        }

        .pagebuilder-column-line {
            @include mobile {
                flex-direction: column;
            }

            .pagebuilder-column {
                @include mobile {
                    width: 100% !important;
                    padding-inline: 16px;
                    margin-inline: 0 !important;
                }
            }
        }
    }

    &-sales,
    &-new,
    &-promotion {
        .ProductListPage {
            @include mobile {
                margin-block-end: 32px;
            }
        }
    }

    &-usps {
        border-top: 8px solid var(--color-white);
        align-items: center;
        justify-content: center;
        padding-block: 8px;

        > .row-full-width-inner {
            max-width: var(--content-wrapper-width);
            width: 100%;
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: space-around;
            list-style: none;

            @include mobile {
                flex-direction: column;
            }

            > li {
                display: flex;
                align-items: center;
                gap: 12px;
                font-weight: bold;
                margin: 0;

                img {
                    object-fit: contain;

                    @include mobile {
                        object-position: initial;
                        width: 32px;
                        height: 32px;
                    }
                }
            }
        }
    }
}
