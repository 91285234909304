/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable declaration-no-important,number-max-precision */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --footer-content-height: 478.5px;
    --footer-copyright-height: 50px;
    --footer-nav-height: 48px;
    --footer-total-height: calc(env(safe-area-inset-bottom) + var(--footer-nav-height));
    --footer-bg-color: #333333;
    --footer-copyright-color: rgba(255, 255, 255, .6);
}

.Footer {
    $column-count: 5;

    @include mobile {
        margin-block-end: var(--navigation-tabs-height);
    }

    &-CopyrightContentWrapper {
        background-color: var(--secondary-base-color);
    }

    &-CopyrightContent {
        padding: 10px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: var(--footer-copyright-height);

        @include desktop {
            padding-inline: 32px;
            padding-block-end: 48px;
        }

        a {
            color: var(--paragraph-color);
            font-weight: normal;
        }
    }

    &-Copyright {
        font-size: 12px;
        text-align: center;
        color: var(--secondary-dark-color);
        padding-inline: 16px;
        line-height: 16px;

        a {
            color: inherit;
            font-weight: 400;
            font-size: 12px;

            &:hover {
                color: var(--primary-base-color);
            }
        }
    }

    &-Content {
        height: var(--footer-content-height);
        background-color: var(--footer-bg-color);

        @include mobile {
            height: auto;
        }
    }

    &-Columns {
        display: grid;
        grid-gap: 20px 10px;
        
        @include desktop {
            padding-block: 70px 50px;
            height: 400px;
            grid-template-columns: repeat(5, 1fr);
            grid-template-areas: "col1 col2 col3 col4 col5"
                "bottom bottom bottom bottom bottom";
        }

        @include tablet {
            padding-block: 40px 20px;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas: "col1 col2 col3"
                "col4 col5 col5"
                "bottom bottom bottom";
        }

        @include mobile {
            padding: 30px 16px 15px;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto auto auto auto auto;
            grid-template-areas: "col1"
                "col2"
                "col3"
                "col4"
                "col5"
                "bottom";
            text-align: center;

            .PaymentMethods-Wrapper {
                justify-content: center;

                ul {
                    @include mobile {
                        justify-content: center;
                    }
                }
            }
        }

        .CmsBlock-Wrapper {
            display: contents;

            > [data-content-type="block"] {
                * {
                    color: var(--color-white);
                }

                a {
                    font-weight: normal;
                    text-decoration: underline;
                }
            }
        }

        .FooterBottom-Wrapper {
            margin-block-start: 20px;
            grid-area: bottom;

            * {
                color: var(--footer-copyright-color) !important;
            }
        }

        .Footer-Column_isNewsletter {
            grid-area: col5;

            input {
                color: var(--color-black);

                &::placeholder {
                    color: var(--color-black) !important;
                    opacity: 1;
                }
            }
        }

        .PaymentMethods-Wrapper {
            ul {
                display: grid;
                grid-template-columns: 100px 100px;
                grid-auto-rows: auto;
                gap: 15px;
                align-items: center;

                li {
                    margin: 0;
                    justify-self: center;
                }
            }
        }
    }

    &-Column {
        @include mobile {
            width: 100%;
        }

        &:not(:last-of-type) {
            @include mobile {
                margin-block-end: 30px;
            }

            @include desktop {
                padding-inline-end: 20px;
                max-width: calc(100% / #{ $column-count - 1 });
            }
        }
    }

    &-ColumnTitle {
        margin: 0;
        margin-block-end: 15px;
        color: var(--color-white);

        @include desktop {
            margin-block-end: 20px;
        }
    }

    &-ColumnContent {
        display: flex;
        flex-direction: column;

        &_direction_horizontal {
            flex-direction: row;

            @include mobile {
                justify-content: center;
            }
        }
    }

    &-ColumnItem {
        border-radius: 2px;
        color: inherit;
        margin-block-end: 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        &:last-of-type {
            margin: 0;
        }

        &:hover {
            text-decoration: underline;
        }

        &Image {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                // important required as default 100% width is passed as inline style
                // + you can't achieve same centering with just passing '16px' as inline style for the case
                width: 16px!important;
                inset-block-start: 50%;
                inset-inline-start: 50%;
                transform: translate(-50%, -50%);

                &:dir(rtl) {
                    transform: translate(50%, -50%);
                }

                &:hover {
                    opacity: 0.6;
                }
            }
        }

        &_type_image {
            width: 40px;
            height: 40px;
            border: 1px solid var(--secondary-dark-color);

            &:not(:last-of-type) {
                margin-inline-end: 20px;
            }
        }
    }

    &-CmsBlockWrapper {
        .CmsBlock-Wrapper {
            flex: 1;
        }

        .ContentWrapper .Footer-Columns {
            padding: 0;
        }

        .Footer-Column_isNewsletter {
            @include desktop {
                padding-inline-start: 100px;
            }
        }
    }

    ul {
        li {
            &::before {
                display: none;
            }
        }
    }

    .CmsBlock-Wrapper_type_payment {
        ul {
            float: inline-start;
            margin-inline-end: 15px;
        }
    }

    .payment-logo {
        width: 100%;
        max-width: 80px;
        height: auto;
        display: block;
    }
}
