.PromoLinks {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    padding: 0 20px;

    @include desktop {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
    }

    &-Item {
        width: 100%;
        padding-block-end: 60%;
        position: relative;

        .PromoItem-Image {
            position: absolute;
            width: 100% ;
            height: 100%;
            object-fit: cover;
        }

        .block-cms-link {
            position: static;
            
            a {
                position: absolute;
                inset-block-start: 0;
                inset-inline-start: 0;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                padding: 0 30px 30px;

                span {
                    @include button;
                }
            }
        }
    }
}

.CategoryPromo {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    padding: 0 20px;

    @include ultra-narrow-desktop {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }

    @include narrow-desktop {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }

    @include wide-desktop {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
    }

    &-Item {
        width: 100%;
        padding-block-end: 60%;
        position: relative;

        .PromoItem-Image {
            position: absolute;
            width: 100% ;
            height: 100%;
            object-fit: cover;
        }

        .block-category-link {
            position: static;

            a {
                position: absolute;
                inset-block-start: 0;
                inset-inline-start: 0;
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                span {
                    display: flex;
                    justify-content: center;
                    background-color: fade-out($default-primary-base-color, .5);
                    padding: 10px;
                    color: white;
                    text-transform: uppercase;
                    font-size: 16px;
                    margin-block-end: 20px;

                    @include mobile {
                        padding: 15px;
                    }
                }
            }
        }
    }
}
