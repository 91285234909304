/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Dropdown {
    position: relative;
    height: 40px;

    @include mobile {
        height: auto;
    }

    &-Toggle {
        width: 100%;
        border: 1px solid white;
        background-color: white;
        height: 100%;
        color: $black;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline: 15px;
        padding-block: 1rem;
        cursor: pointer;

        svg {
            fill: $black;
            height: 12px;
        }
    }

    &-Heading {
        // padding-block-start: 4px;
    }

    &-Options {
        display: none;
        position: absolute;
        inset-block-start: 100%;
        inset-inline-start: 0;
        margin-block-start: 5px;
        width: 100%;
        background-color: #F6F3F3;
        border-style: solid;
        border-width: 1px;
        border-color: #E7E4E4;
        padding: 10px 15px;
        z-index: 10;
        box-shadow: 5px 5px 20px 0px #0000000D;

        @include mobile {
            position: relative;
        }

        @include desktop {
            max-height: 400px;
            overflow-y: auto;
        }

        .ProductAttributeValue-Text {
            label {
                &:first-of-type {
                    .input-control {
                        @include mobile {
                            margin-block-start: -3px;
                        }

                        @include desktop {
                            margin-block-start: 2px;
                        }
                    }
                }
            }
        }

        &Heading {
            font-size: 2rem;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            padding-block-start: 1rem;
            padding-block-end: 1.5rem;
        }
    }

    &_isOpen {
        .Dropdown {
            &-Options {
                display: block;
            }

            &-Toggle {
                color: white;
                border: 1px solid var(--primary-base-color);
                background-color: var(--primary-base-color);
        
                svg {
                    fill: white;
                }
            }
        }
    }
}
