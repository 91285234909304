/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable declaration-no-important */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.Breadcrumb {
    $crumb-padding: 12px;

    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    padding-inline-end: $crumb-padding;
    margin: 0 !important;

    @include mobile {
        font-size: 14px;
    }

    .ChevronIcon {
        filter: invert(57%) sepia(0%) saturate(1%) hue-rotate(105deg) brightness(93%) contrast(90%);
        display: inline-block;
        margin-inline-start: $crumb-padding;
        width: 12px;
        height: 12px;
        inset-block-end: -2px;
    }

    &:last-child {
        .Breadcrumb-Divider,
        .ChevronIcon {
            display: none;
        }

        .Breadcrumb-Link-Name {
            color: var(--breadcrumbs-active-color);
            font-weight: bold;
        }

        :link {
            pointer-events: none;
        }
    }

    &::before {
        content: none;
    }

    &-Link {
        display: flex;
        align-items: center;
        gap: 12px;
        color: var(--breadcrumbs-color);
        font-weight: 400;
        font-size: 12px;

        &:hover {
            color: var(--link-color);
        }

        &-Name {
            color: inherit;
        }
    }
}
