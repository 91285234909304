/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/parts';

.PageBuilderSlider {
    .Slider {
        &-Arrow {
            background-color: var(--primary-base-color);
            border-radius: var(--button-border-radius);
            height: 50px;
            width: 50px;
            padding: 7px;
            margin-inline-start: 20px;
            margin-inline-end: 20px;
            opacity: .5;

            &_isPrev {
                inset-inline-start: 0;
            }

            &_isNext {
                inset-inline-end: 0;
            }

            .ChevronIcon {
                fill: var(--color-white);
            }

            &:hover {
                opacity: 1;
            }
        }

        .pagebuilder-slide-wrapper {
            .pagebuilder-overlay {
                &:not(.pagebuilder-poster-overlay) {
                    max-width: var(--content-wrapper-width);
                    margin: 0 auto;
                }
            }
        }
    }
}
