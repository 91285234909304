/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CarouselScrollItem {
    display: flex;
    scroll-snap-align: start;
    border: none;
    transition: border-color var(--animation-speed), filter var(--animation-speed);
    filter: opacity(.3);
    flex: 1;
    cursor: pointer;
    height: 100%;
    border-radius: 0;
    width: 70px;

    & + .CarouselScrollItem {
        margin-inline-start: var(--carousel-scroll-gap);
    }

    &_isActive {
        filter: opacity(1);
        border-color: var(--option-border-color);
    }

    &:focus,
    &:hover {
        filter: opacity(1);
    }
}
