/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --breadcrumbs-height: 40px;
    --breadcrumbs-active-color: var(--primary-base-color);
    --breadcrumbs-color: var(--color-black);
}

.Breadcrumbs {
    $crumb-padding: 11px;
    $arrow-size: 4px;

    padding-block: 8px;
    background-color: var(--color-white);

    @include mobile {
        --breadcrumbs-background: #fff;

        display: none;
    }

    &-List {
        list-style: none;
        padding: 8px 0;
        display: flex;
        align-items: center;
    }
}
